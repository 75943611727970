import { type JSX, useEffect } from 'react';

import { Box, Container } from '@change-corgi/design-system/layout';

import { useHeaderHeight } from 'src/app/app/layout';
import { useGoogleOneTapLogin } from 'src/app/pages/loginOrSignup/shared/hooks';
import { useFocusFallbackRef } from 'src/app/shared/hooks/focus';
import { LOGIN_OR_SIGNUP_WIDTH } from 'src/app/shared/utils/loginOrJoin';

import { LoginOrSignupBanner } from '../components/LoginOrSignupBanner';
import { LoginOrSignupBody } from '../components/LoginOrSignupBody';
import { LoginOrSignupFooter } from '../components/LoginOrSignupFooter';
import { LoginOrSignupHeader } from '../components/LoginOrSignupHeader';

import { useTrackLoginOrSingupPageView } from './hooks/useTrackLoginOrSingupPageView';

export function LoginOrSignupLayout(): JSX.Element {
	useTrackLoginOrSingupPageView();

	const ref = useFocusFallbackRef();

	const headerHeight = useHeaderHeight();

	const { displayGoogleOneTap } = useGoogleOneTapLogin({});

	useEffect(() => {
		displayGoogleOneTap();
	}, [displayGoogleOneTap]);

	return (
		<>
			<LoginOrSignupBanner />
			<Box ref={ref}>
				<Container
					variant="small"
					sx={{
						width: ['100%', LOGIN_OR_SIGNUP_WIDTH],
						// setting a min height so that the focus outline looks a bit better when navigating
						minHeight: [`calc(100vh - ${headerHeight.mobile}px)`, `calc(100vh - ${headerHeight.desktop}px)`],
					}}
				>
					<LoginOrSignupHeader />
					<Box my={32}>
						<LoginOrSignupBody />
					</Box>
					<LoginOrSignupFooter />
				</Container>
			</Box>
		</>
	);
}
