import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import { LoginByEmail, type LoginByEmailMutation, type LoginByEmailMutationVariables } from './loginByEmail.graphql';

export type LoginByEmailMutationInput = LoginByEmailMutationVariables['input'];
export type LoginMutationResponse = LoginByEmailMutation['loginByEmail'];

export async function loginByEmail(
	input: LoginByEmailMutationInput,
	{ gql: { fetch } }: UtilityContext,
): Promise<LoginMutationResponse> {
	const { data, errors } = await fetch<LoginByEmailMutation, LoginByEmailMutationVariables>({
		path: '/login',
		query: LoginByEmail,
		variables: {
			input,
		},
		rejectOnError: false,
		batched: false, // to ensure this rate limited mutation is not batched
	});

	if (errors) throw new Error(JSON.stringify(errors));
	if (!data || !data.loginByEmail) throw new Error('No data returned from query.');
	return data.loginByEmail;
}
