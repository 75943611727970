import type { JSX } from 'react';

import { Redirect } from '@change-corgi/core/react/router';
import { Loader } from '@change-corgi/design-system/components/progressiveDisclosure';

import { withPrefetchedData } from 'src/shared/prefetch';

import { LoginOrSignupPageContextProvider, prefetchPageContext } from 'src/app/pages/loginOrSignup/pageContext';
import { useGetRedirectUrl } from 'src/app/pages/loginOrSignup/shared/hooks';
import { useLoginState } from 'src/app/shared/hooks/session';

import { LoginOrSignupLayout } from '../layout';

export const LoginOrSignup = withPrefetchedData(
	function LoginOrSignup(): JSX.Element | null {
		const loginState = useLoginState();
		const getRedirectUrl = useGetRedirectUrl();

		if (loginState === 'AUTHENTICATED') {
			return <Redirect to={getRedirectUrl()} />;
		}

		return (
			<LoginOrSignupPageContextProvider loadingFallback={<Loader size="m" my={256} mx="auto" />}>
				<LoginOrSignupLayout />
			</LoginOrSignupPageContextProvider>
		);
	},
	{
		prefetchName: __MODULE_HASH__,
		prefetchData: async (context) => {
			await prefetchPageContext(context);
		},
	},
);
