/* eslint-disable max-lines-per-function */
import { useCallback } from 'react';

import isEmail from 'validator/lib/isEmail';

import { useI18n } from '@change-corgi/core/react/i18n';

import { useLoginOrSignupPageFcm, useLoginOrSignupPageState } from 'src/app/pages/loginOrSignup/pageContext';
import type { LoginOrSignupStep } from 'src/app/pages/loginOrSignup/shared/types';

import { useLoginOrSignupTracking } from '../../track';
import { useLoginUserFlow } from '../../useLoginUserFlow';

import { useGotoFinishLogin } from './useGotoFinishLogin';
import { useGotoFinishSignup } from './useGotoFinishSignup';
import { useGotoLoginOrSignup } from './useGotoLoginOrSignup';

type UseGotoNextPageHook = {
	gotoNextStep: () => void | Promise<void>;
};

const useGotoNextPage = (): UseGotoNextPageHook => {
	const [{ currentStep, email }, { setFormFieldError, gotoStep }] = useLoginOrSignupPageState();
	const { translate } = useI18n();
	const userFlow = useLoginUserFlow();

	const gotoLoginOrSignup = useGotoLoginOrSignup();
	const gotoFinishLogin = useGotoFinishLogin();
	const gotoFinishSignup = useGotoFinishSignup();

	const {
		trackLoginPasswordCreateClick,
		trackLoginNewUserNameClickContinue,
		trackLoginButtonClick,
		trackLoginOrJoinAttempt,
	} = useLoginOrSignupTracking();

	const { futureSapMarketingConsentRequired: isFutureSapMarketingConsentRequired } = useLoginOrSignupPageFcm();

	const gotoNextStep = useCallback(async () => {
		switch (currentStep) {
			case 'email':
				if (isEmail(email)) {
					await gotoLoginOrSignup();
				} else {
					setFormFieldError('email', translate('corgi.login_or_signup.error.email.invalid'));
				}
				break;
			case 'login':
				trackLoginButtonClick();
				trackLoginOrJoinAttempt('log_in', 'email_pswd', true, userFlow);
				await gotoFinishLogin();
				break;
			case 'signup':
				trackLoginPasswordCreateClick();
				gotoStep('name', { canGoBack: true });
				break;
			case 'name':
				trackLoginNewUserNameClickContinue();
				/**
				 * If isFutureSapMarketingConsentRequired is true,
				 * the user is required to provide their marketing consent.
				 */
				if (isFutureSapMarketingConsentRequired) {
					gotoStep('marketing_consent');
				} else {
					await gotoFinishSignup();
				}
				break;
			case 'marketing_consent':
				await gotoFinishSignup();
				break;
			default:
				throw new Error('Page not found.');
		}
	}, [
		currentStep,
		email,
		trackLoginButtonClick,
		trackLoginOrJoinAttempt,
		gotoFinishLogin,
		trackLoginPasswordCreateClick,
		setFormFieldError,
		gotoStep,
		trackLoginNewUserNameClickContinue,
		isFutureSapMarketingConsentRequired,
		gotoFinishSignup,
		gotoLoginOrSignup,
		translate,
		userFlow,
	]);

	return { gotoNextStep };
};

type UseGotoPageHook = {
	gotoStep: (step: LoginOrSignupStep) => void;
};

const useGotoPage = (): UseGotoPageHook => {
	const [, { gotoStep }] = useLoginOrSignupPageState();

	return { gotoStep };
};

export type LoginOrSignupNavigationHook = UseGotoNextPageHook & UseGotoPageHook;

export const useLoginOrSignupNavigation = (): LoginOrSignupNavigationHook => {
	const { gotoNextStep } = useGotoNextPage();
	const { gotoStep } = useGotoPage();

	return { gotoNextStep, gotoStep };
};
