import { useCallback } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { useLoginOrSignupPageState } from 'src/app/pages/loginOrSignup/pageContext';

import { useGotoRedirect } from '../../../useGotoRedirect';
import { useLoginOrSignup } from '../../../useLoginOrSignup';
import { useLoginUserFlow } from '../../../useLoginUserFlow';
import { useTrackSuccess } from '../useTrackSuccess';

import { useHandleLoginResponseStatus } from './useHandleLoginResponseStatus';

type GotoFinishLoginOrSignupHook = () => Promise<void>;

export function useGotoFinishLogin(): GotoFinishLoginOrSignupHook {
	const utilityContext = useUtilityContext();
	const [{ email, password }, { setLoading, setSuccess, setError, clearLoading }] = useLoginOrSignupPageState();
	const { login } = useLoginOrSignup();
	const gotoRedirectTo = useGotoRedirect();
	const handleLoginResponseStatus = useHandleLoginResponseStatus();
	const trackSuccess = useTrackSuccess();
	const { translate } = useI18n();
	const userFlow = useLoginUserFlow();

	const gotoFinishLogin = useCallback(async () => {
		setLoading();

		try {
			const result = await login({ email, password }, utilityContext);

			if (!handleLoginResponseStatus(result)) {
				clearLoading();
				return;
			}

			setSuccess();

			await trackSuccess({
				newUser: false,
				loginMethod: 'email',
				loginOrJoinMethod: 'email_pswd',
				loginType: 'email_login',
				userFlow,
			});
			gotoRedirectTo({ loginOrJoinMethod: 'email_pswd' });
		} catch (err) {
			clearLoading();
			setError(translate('corgi.login_or_signup.error.network'));
		}
	}, [
		setLoading,
		setError,
		setSuccess,
		clearLoading,
		email,
		gotoRedirectTo,
		login,
		handleLoginResponseStatus,
		password,
		trackSuccess,
		utilityContext,
		translate,
		userFlow,
	]);

	return gotoFinishLogin;
}
