import type { LoginOrSignupState, LoginOrSignupStepFormFieldName } from 'src/app/pages/loginOrSignup/shared/types';

export function setFormFieldValid(state: LoginOrSignupState, name: LoginOrSignupStepFormFieldName): LoginOrSignupState {
	const formErrors = {
		...state.formErrors,
	};
	delete formErrors[name];
	return {
		...state,
		formErrors: Object.keys(formErrors).length ? formErrors : null,
	};
}
