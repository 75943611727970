import type { LoginOrSignupState, LoginOrSignupStepFormFieldName } from 'src/app/pages/loginOrSignup/shared/types';

export function setFormFieldError(
	state: LoginOrSignupState,
	name: LoginOrSignupStepFormFieldName,
	error = '',
): LoginOrSignupState {
	return {
		...state,
		formErrors: {
			...state.formErrors,
			[name]: error,
		},
	};
}
