import type { JSX } from 'react';

import { parseHtml } from '@change-corgi/core/react/html';
import { useI18n } from '@change-corgi/core/react/i18n';
import { Separator } from '@change-corgi/design-system/components/content';
import { Box } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import { LoginEmailForm } from './components/LoginEmailForm';
import { LoginSocialButtons } from './components/LoginSocialButtons';
import { useEmailStep } from './hooks/useEmailStep';

const StyledRule = Separator;

export function EmailStep(): JSX.Element {
	const { translate } = useI18n();

	return (
		<Box px={[16, 0]}>
			<EmailStepInner />
			<Text as="div" mt={16} size="small" color="neutral-grey600" data-testid="disclaimer-text">
				{parseHtml(translate('corgi.login_or_signup.email.disclaimer'), { allowedAttrs: ['target'] })}
			</Text>
		</Box>
	);
}

function EmailStepInner(): JSX.Element {
	const {
		data: { showSocialButtons },
	} = useEmailStep();
	const { translate } = useI18n();

	if (!showSocialButtons) return <LoginEmailForm />;

	return (
		<>
			<LoginEmailForm />
			<StyledRule label={translate('corgi.login_or_signup.email.or')} mt={40} mb={36} />
			<LoginSocialButtons />
		</>
	);
}
