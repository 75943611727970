import type { JSX } from 'react';

import { Box, Flex } from '@change-corgi/design-system/layout';

import { FacebookButton } from './components/FacebookButton';
import { GoogleButton } from './components/GoogleButton';
import { useLoginSocialButtons } from './hooks/useLoginSocialButtons';

const MAPPING = {
	facebook: FacebookButton,
	google: GoogleButton,
};

export function LoginSocialButtons(): JSX.Element | null {
	const {
		data: { buttons },
	} = useLoginSocialButtons();

	if (!buttons.length) return null;

	return (
		<Flex sx={{ flexDirection: 'column', alignItems: 'center', gap: 16 }}>
			{buttons
				.map((button) => MAPPING[button])
				.map((Cmp, idx) => (
					<Box
						// eslint-disable-next-line react/no-array-index-key
						key={idx}
						sx={{
							width: '100%',
							// Setting this because google forces its button to this max width
							maxWidth: 400,
						}}
					>
						<Cmp />
					</Box>
				))}
		</Flex>
	);
}
