import { useCallback } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';

import { useLoginOrSignupPageState } from 'src/app/pages/loginOrSignup/pageContext';

import type { LoginMutationResponse } from '../../../../useLoginOrSignup/api/loginByEmail';

export function useHandleLoginResponseStatus(): (response: LoginMutationResponse) => boolean {
	const [, { setRetryMinimumTimestamp, setError, clearError, setFormFieldError }] = useLoginOrSignupPageState();
	const { localizeNumber, translate, translatePlural } = useI18n();

	return useCallback(
		(response) => {
			const { status, waitTime } = response;

			switch (status) {
				case 'LOGGED_IN':
					return true;
				// for staff users who attempt to login without SAML
				case 'INVALID_LOGIN_METHOD':
					setError(translate('corgi.login_or_signup.error.login.invalid_login_method'));
					return false;
				// for users who input passwords that don't match their email addresses provided
				case 'NOT_FOUND':
					clearError();
					setFormFieldError('password', translate('corgi.login_or_signup.error.login.not_found'));
					return false;
				// for users who attempt to login unsuccessfully multiple times
				case 'RATE_LIMITED': {
					const waitInMinutes = waitTime ? Math.ceil(waitTime / 60) : 0;
					const waitTimeInMilliseconds = waitTime ? waitTime * 1000 : 0;
					setRetryMinimumTimestamp(Date.now() + waitTimeInMilliseconds);
					setError(
						translatePlural('corgi.login_or_signup.error.login.rate_limited_with_plural', waitInMinutes, {
							wait: localizeNumber(waitInMinutes),
						}),
					);
					return false;
				}
				default:
					setError(translate('corgi.login_or_signup.error.network'));
					return false;
			}
		},
		[setRetryMinimumTimestamp, setError, clearError, setFormFieldError, localizeNumber, translate, translatePlural],
	);
}
