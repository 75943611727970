import type { JSX } from 'react';

import { EmailStep } from './components/EmailStep';
import { EmailVerificationStep } from './components/EmailVerificationStep';
import { ForgotPasswordStep } from './components/ForgotPasswordStep';
import { LoginStep } from './components/LoginStep';
import { MarketingConsentStep } from './components/MarketingConsentStep';
import { NameStep } from './components/NameStep';
import { NoPasswordStep } from './components/NoPasswordStep';
import { SignupStep } from './components/SignupStep';
import { useLoginOrSignupBody } from './hooks/useLoginOrSignupBody';

export function LoginOrSignupBody(): JSX.Element {
	const {
		data: { step },
	} = useLoginOrSignupBody();

	switch (step) {
		case 'email':
			return <EmailStep />;
		case 'forgot_password':
			return <ForgotPasswordStep />;
		case 'signup':
			return <SignupStep />;
		case 'login':
			return <LoginStep />;
		case 'name':
			return <NameStep />;
		case 'marketing_consent':
			return <MarketingConsentStep />;
		case 'email_verification':
			return <EmailVerificationStep />;
		case 'no_password':
			return <NoPasswordStep />;
		default:
			throw new Error('Invalid current step');
	}
}
