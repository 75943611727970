import type {
	LoginOrSignupStep,
	LoginOrSignupStepFormFieldName,
	LoginOrSignupStepFormState,
} from 'src/app/pages/loginOrSignup/shared/types';

import { isPassword } from '../password';

type Errors = Partial<Record<LoginOrSignupStepFormFieldName, boolean>>;

type StepValidator = (formValues: Partial<LoginOrSignupStepFormState>) => Errors;

const STEP_VALIDATORS: Record<LoginOrSignupStep, StepValidator> = {
	email: ({ email }) => ({
		email: !!email,
	}),
	login: ({ password }) => ({
		password: !!password,
	}),
	signup: ({ password }) => ({
		password: !!password && isPassword(password, { type: 'strong' }),
	}),
	name: ({ firstName, lastName }) => ({
		firstName: !!firstName,
		lastName: !!lastName,
	}),
	marketing_consent: ({ marketingConsent }) => ({
		marketingConsent: !!marketingConsent,
	}),
	email_verification: () => ({}),
	forgot_password: () => ({
		password: false,
	}),
	no_password: () => ({
		password: false,
	}),
};

export function getStepValidator(step: LoginOrSignupStep): StepValidator {
	return STEP_VALIDATORS[step];
}
