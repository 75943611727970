import { useCallback, useState } from 'react';

type DisplayMode = 'text' | 'password';

type Result = ModelHookResult<
	{
		displayMode: DisplayMode;
	},
	{
		toggleDisplayMode: () => void;
	}
>;

export function usePasswordInput(): Result {
	const [displayMode, setDisplayMode] = useState<DisplayMode>('password');
	const toggleDisplayMode = useCallback(() => {
		setDisplayMode((t) => (t === 'password' ? 'text' : 'password'));
	}, []);

	return {
		data: { displayMode },
		actions: { toggleDisplayMode },
	};
}
