import type { JSX } from 'react';

import { translationKey } from '@change-corgi/core/i18n';

import { NavButtons } from './components/NavButtons';
import { useLoginOrSignupFooter } from './hooks/useLoginOrSignupFooter';

export function LoginOrSignupFooter(): JSX.Element | null {
	const {
		data: { loading, valid, canNavigateBack, canNavigateForward },
		actions: { gotoNextStep, gotoPreviousStep },
	} = useLoginOrSignupFooter();

	return (
		<NavButtons
			forwardFunc={gotoNextStep}
			forwardText={canNavigateForward ? translationKey('corgi.sap.button.forward') : null}
			backwardFunc={gotoPreviousStep}
			desktopBackwardText={canNavigateBack ? translationKey('corgi.sap.button.back') : null}
			mobileBackwardText={canNavigateBack ? translationKey('corgi.sap.button.back') : null}
			validated={valid}
			nextStepLoading={loading}
		/>
	);
}
