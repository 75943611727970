import type { TranslationKey } from '@change-corgi/core/i18n';
import { translationKey } from '@change-corgi/core/i18n';

import type { LoginOrSignupStep } from 'src/app/pages/loginOrSignup/shared/types';

type StepProp = {
	readonly title: TranslationKey;
	readonly subtitle?: TranslationKey;
};

type StepProps = Record<LoginOrSignupStep, StepProp>;

const stepProps: StepProps = {
	email: {
		title: translationKey('corgi.login_or_signup.email.title'),
	},
	login: {
		subtitle: translationKey('corgi.login_or_signup.login_password.subtitle'),
		title: translationKey('corgi.login_or_signup.login_password.title'),
	},
	signup: {
		subtitle: translationKey('corgi.login_or_signup.create_password.subtitle'),
		title: translationKey('corgi.login_or_signup.create_password.title'),
	},
	name: {
		subtitle: translationKey('corgi.login_or_signup.name.subtitle'),
		title: translationKey('corgi.login_or_signup.name.title'),
	},
	marketing_consent: {
		subtitle: translationKey('corgi.login_or_signup.opt_in.subtitle'),
		title: translationKey('corgi.login_or_signup.opt_in.title'),
	},
	email_verification: {
		subtitle: translationKey('corgi.login_or_signup.verify_email.subtitle'),
		title: translationKey('corgi.login_or_signup.verify_email.title'),
	},
	forgot_password: {
		subtitle: translationKey('corgi.login_or_signup.forgot_password.subtitle'),
		title: translationKey('corgi.login_or_signup.forgot_password.title'),
	},
	no_password: {
		subtitle: translationKey('corgi.login_or_signup.no_password.subtitle'),
		title: translationKey('corgi.login_or_signup.no_password.title'),
	},
};

export function getI18nKeys(step: LoginOrSignupStep): StepProp {
	return stepProps[step];
}
