import { useMemo } from 'react';

import qs from 'qs';

import { useQueryParamValue } from 'src/app/shared/hooks/location';

import { useGetRedirectUrl } from '../useGetRedirectUrl';

export function useLoginUserFlow(): string {
	const userFlowParam = useQueryParamValue('user_flow');
	const getRedirectUrl = useGetRedirectUrl();

	// If the current URL doesn't have a user_flow query param, then check to see if there's one
	// in the redirect URL.
	const redirectUserFlowParam = useMemo(() => {
		const redirectUrl = getRedirectUrl();
		const [, search] = redirectUrl.split('?');
		if (search) {
			const p = qs.parse(search, { ignoreQueryPrefix: true }).user_flow;
			return typeof p === 'string' ? p : undefined;
		}
		return undefined;
	}, [getRedirectUrl]);

	return userFlowParam || redirectUserFlowParam || 'other';
}
