import { useCallback } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';

import { useLoginOrSignupPageState } from 'src/app/pages/loginOrSignup/pageContext';
import { useCanSubmitForm, useLoginOrSignupState } from 'src/app/pages/loginOrSignup/shared/hooks';
import type { PendingUserConsentInput } from 'src/app/pages/loginOrSignup/shared/types';

type StepData = { marketingConsent: boolean | undefined };

type Result = ModelHookResult<
	StepData,
	{
		setStepData: (data: Partial<StepData>) => void;
		submitStep: () => Promise<void>;
	}
>;

export function useMarketingConsentStep(): Result {
	const { gotoNextStep } = useLoginOrSignupState();
	const [{ marketingConsent }, { setFormData }] = useLoginOrSignupPageState();
	const { translate } = useI18n();

	const canSubmit = useCanSubmitForm();

	const submitStep = useCallback(async () => {
		if (!canSubmit) return;
		await gotoNextStep();
	}, [gotoNextStep, canSubmit]);

	return {
		data: {
			marketingConsent: marketingConsent?.marketingCommsConsent,
		},
		actions: {
			setStepData: useCallback(
				(data) => {
					if ('marketingConsent' in data) {
						setFormData({
							marketingConsent: (() => {
								const marketingCommsConsent = data.marketingConsent || false;
								const consentName = 'global_consent_initial_eu_exp_sap_signup_modal';
								// FIXME: is the missing space or new line between title and subtitle on purpose?
								// FIXME: shouldn't we retrieve the title/subtitle from stepProps?
								const consentText =
									translate('corgi.login_or_signup.opt_in.title') + translate('corgi.login_or_signup.opt_in.subtitle');

								const userConsent: PendingUserConsentInput = {
									marketingCommsConsent,
									name: consentName,
									text: consentText,
								};

								return userConsent;
							})(),
						});
					}
				},
				[setFormData, translate],
			),
			submitStep,
		},
	};
}
