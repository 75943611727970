import type { LoginOrSignupState, LoginOrSignupStep } from 'src/app/pages/loginOrSignup/shared/types';
import { isError, isLoading } from 'src/app/shared/utils/async';

import { validateCurrentStepFormFields } from './validateCurrentStepFormFields';

export const navigationMutations = {
	gotoStep: (
		state: LoginOrSignupState,
		step: LoginOrSignupStep,
		{ canGoBack }: { canGoBack?: boolean } = {},
	): LoginOrSignupState =>
		validateCurrentStepFormFields({
			...state,
			currentStep: step,
			previousStep: canGoBack
				? {
						currentStep: state.currentStep,
						previousStep: state.previousStep,
					}
				: null,
			stepState: null,
			formErrors: null,
			banner: null,
			// reset password on specific step changes, for security purposes
			// we need to conserve the password when moving through signup steps,
			// as the actual API call is made from a different step than the one with the password
			// FIXME: find a cleaner way to implement this logic?
			...(['login', 'signup', 'email', 'forgot_password'].includes(step) ? { password: '' } : undefined),
			// reset retry timestamp as it's step-specific
			retryMinimumTimestamp: 0,
		}),

	gotoPreviousStep: (state: LoginOrSignupState): LoginOrSignupState => {
		if (!state.previousStep) return state;
		const step = state.previousStep.currentStep;
		return validateCurrentStepFormFields({
			...state,
			...state.previousStep,
			stepState: null,
			formErrors: null,
			banner: null,
			// reset password on specific step changes, for security purposes
			// we need to conserve the password when moving through signup steps,
			// as the actual API call is made from a different step than the one with the password
			// FIXME: find a cleaner way to implement this logic?
			...(['login', 'signup', 'email'].includes(step) ? { password: '' } : undefined),
			// reset retry timestamp as it's step-specific
			retryMinimumTimestamp: 0,
		});
	},

	setLoading: (state: LoginOrSignupState): LoginOrSignupState => ({
		...state,
		stepState: { status: 'loading' },
	}),

	clearLoading: (state: LoginOrSignupState): LoginOrSignupState => {
		if (!state.stepState || !isLoading(state.stepState)) return state;
		return {
			...state,
			stepState: null,
		};
	},

	setError: (state: LoginOrSignupState, error: string): LoginOrSignupState => ({
		...state,
		stepState: { status: 'error', error },
	}),

	clearError: (state: LoginOrSignupState): LoginOrSignupState => {
		if (!state.stepState || !isError(state.stepState)) return state;
		return {
			...state,
			stepState: null,
		};
	},

	setSuccess: (state: LoginOrSignupState): LoginOrSignupState => {
		return {
			...state,
			stepState: null,
		};
	},
};
