import type { JSX } from 'react';

import { Translate, useI18n } from '@change-corgi/core/react/i18n';
import { Input } from '@change-corgi/design-system/components/forms';
import { Box } from '@change-corgi/design-system/layout';
import { buttonResetStyles } from '@change-corgi/design-system/reset';

import { usePasswordInput } from './hooks/usePasswordInput';

type Props = {
	value: string;
	showHelp?: boolean;
	error?: string | null;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export function PasswordInput({ value: password, showHelp, error, onChange }: Props): JSX.Element {
	const {
		data: { displayMode },
		actions: { toggleDisplayMode },
	} = usePasswordInput();
	const { translate } = useI18n();

	return (
		<Box sx={{ position: 'relative' }}>
			{password ? (
				<button
					type="button"
					sx={{
						...buttonResetStyles,
						position: 'absolute',
						px: 4,
						py: 2,
						top: -4,
						right: 0,
						textDecoration: 'underline',
					}}
					aria-label={translate(
						`corgi.login_or_signup.button.a11y.show_password_button.${displayMode === 'password' ? 'show' : 'hide'}`,
					)}
					onClick={toggleDisplayMode}
					aria-pressed={displayMode === 'text'}
					data-testid="password-visibility-toggle"
				>
					<Translate
						value={`corgi.login_or_signup.button.show_password_button.${displayMode === 'password' ? 'show' : 'hide'}`}
					/>
				</button>
			) : null}
			<Input
				type={displayMode}
				id="loginPassword"
				name="password"
				data-testid="password"
				size="large"
				helperText={showHelp ? translate('corgi.login_or_signup.common.labels.password_requirement') : undefined}
				label={translate('corgi.login_or_signup.common.labels.password')}
				onChange={onChange}
				value={password}
				error={error}
			/>
		</Box>
	);
}
