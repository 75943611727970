import { useCallback } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { useLoginOrSignupPageState } from 'src/app/pages/loginOrSignup/pageContext';

import { useLoginOrSignup } from '../../../useLoginOrSignup';

type GotoLoginOrSignupHook = () => Promise<void>;

export function useGotoLoginOrSignup(): GotoLoginOrSignupHook {
	const [state, { setLoading, setSuccess, setError, gotoStep }] = useLoginOrSignupPageState();
	const { getEmailAuthMethod } = useLoginOrSignup();
	const utilityContext = useUtilityContext();
	const { translate } = useI18n();

	const gotoLoginOrSignup = useCallback(async () => {
		setLoading();
		try {
			const authMethod = await getEmailAuthMethod(state.email, utilityContext);
			setSuccess();
			if (authMethod === 'INVALID_EMAIL') {
				// TODO update error with a clearer message explaining what could be wrong with the email address
				setError(translate('fe.errors.user.email.prefix'));
			} else if (authMethod === 'UNKNOWN_USER') {
				gotoStep('signup', { canGoBack: true });
			} else if (authMethod === 'NO_PASSWORD_SET') {
				gotoStep('no_password', { canGoBack: true });
			} else {
				gotoStep('login', { canGoBack: true }); // 'PASSWORD_SET'
			}
		} catch (error: unknown) {
			const loginError = typeof error === 'string' ? error : translate('corgi.login_or_signup.error.network');
			setError(loginError);
		}
	}, [setLoading, setSuccess, setError, gotoStep, getEmailAuthMethod, state.email, utilityContext, translate]);

	return gotoLoginOrSignup;
}
