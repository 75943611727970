import type { JSX, PropsWithChildren } from 'react';

import { useCurrentUser } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';

import { usePageContext } from './hook';
import { LoginOrSignupPageFcmContextProvider } from './pageFcm';
import { LoginOrSignupPageFcmExperimentsContextProvider } from './pageFcmExperiments';
import { LoginOrSignupPageStateContextProvider } from './pageState';

type Props = {
	loadingFallback: JSX.Element;
};

export function LoginOrSignupPageContextProvider({
	loadingFallback,
	children,
}: PropsWithChildren<Props>): JSX.Element | null {
	const pageContextState = usePageContext();
	const user = useCurrentUser();

	if (!isLoaded(pageContextState)) return loadingFallback;

	const { pageFcm, pageFcmExperiments } = pageContextState;

	return (
		<LoginOrSignupPageFcmContextProvider pageFcm={pageFcm}>
			<LoginOrSignupPageFcmExperimentsContextProvider pageFcmExperiments={pageFcmExperiments}>
				<LoginOrSignupPageStateContextProvider email={user?.email}>{children}</LoginOrSignupPageStateContextProvider>
			</LoginOrSignupPageFcmExperimentsContextProvider>
		</LoginOrSignupPageFcmContextProvider>
	);
}
