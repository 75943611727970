import { useEffect, useMemo } from 'react';

import { useMutation } from '@tanstack/react-query';
import isEmail from 'validator/lib/isEmail';

import { useI18n } from '@change-corgi/core/react/i18n';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { useLoginOrSignupPageState } from 'src/app/pages/loginOrSignup/pageContext';
import { useLoginOrSignupTracking } from 'src/app/pages/loginOrSignup/shared/hooks';

import { sendResetPasswordEmail } from './api';

type Result = ModelHookResult<
	{ isGmail: boolean },
	{
		trackOpenInGmailClick: () => void;
	}
>;

export function useNoPasswordStep(): Result {
	const [{ email }, { clearError, clearBanner, setError }] = useLoginOrSignupPageState();
	const { translate } = useI18n();
	const utilityContext = useUtilityContext();
	const { trackNoPasswordGmailClick, trackNoPasswordView } = useLoginOrSignupTracking();
	const isGmail = useMemo(() => !isEmail(email, { host_blacklist: ['gmail.com'] }), [email]);

	const { mutate } = useMutation({
		mutationFn: async () => sendResetPasswordEmail(utilityContext, { email }),
		onMutate: () => {
			clearError();
			clearBanner();
		},
		onError: () => {
			setError(translate('corgi.login_or_signup.error.network'));
		},
	});

	useEffect(() => {
		mutate();
	}, [mutate]);

	useEffect(() => {
		trackNoPasswordView({ isGmail });
	}, [trackNoPasswordView, isGmail]);

	return {
		data: {
			isGmail,
		},
		actions: {
			trackOpenInGmailClick: trackNoPasswordGmailClick,
		},
	};
}
