import { createFcmExperimentsContext } from 'src/app/shared/contexts/experiment';
import type { LoadedStateData } from 'src/app/shared/utils/async';

import { FCM_EXPERIMENT_SIGNATURE_GROWTH_GOOGLE_ONE_TAP_LOGIN } from './config';

export const {
	FcmExperimentsContext: LoginOrSignupPageFcmExperimentsContext,
	FcmExperimentsProvider: LoginOrSignupPageFcmExperimentsContextProvider,
	useFcmExperiments: useLoginOrSignupPageFcmExperiments,
	useAsyncFcmExperiments: useAsyncLoginOrSignupPageFcmExperiments,
	prefetchFcmExperiments: prefetchLoginOrSignupPageFcmExperiments,
} = createFcmExperimentsContext({
	name: 'LoginOrSignupPageFcmExperimentsContext',
	dataProperty: 'pageFcmExperiments',
	configs: {
		googleOneTapLoginExperiment: FCM_EXPERIMENT_SIGNATURE_GROWTH_GOOGLE_ONE_TAP_LOGIN,
	},
	prefetch: true,
});

export type LoginOrSignupPageFcmExperimentsState = ReturnType<typeof useAsyncLoginOrSignupPageFcmExperiments>;
export type LoginOrSignupPageFcmExperiments = LoadedStateData<LoginOrSignupPageFcmExperimentsState>;
