import type { LoginOrSignupState, LoginOrSignupStepFormFieldName } from 'src/app/pages/loginOrSignup/shared/types';

import { setFormFieldError } from './setFormFieldError';
import { setFormFieldValid } from './setFormFieldValid';

function toggleFormFieldValidity(
	state: LoginOrSignupState,
	name: LoginOrSignupStepFormFieldName,
	valid: boolean,
): LoginOrSignupState {
	return valid ? setFormFieldValid(state, name) : setFormFieldError(state, name);
}

export function toggleFormFieldsValidity(
	state: LoginOrSignupState,
	validityStates: Partial<Record<LoginOrSignupStepFormFieldName, boolean>>,
): LoginOrSignupState {
	return Object.entries(validityStates).reduce<LoginOrSignupState>((acc, [name, valid]) => {
		return toggleFormFieldValidity(acc, name as LoginOrSignupStepFormFieldName, valid);
	}, state);
}
