import type { LoginOrSignupStep } from 'src/app/pages/loginOrSignup/shared/types';

type StepProp = {
	readonly canNavigateForward?: boolean;
};

type StepProps = Record<LoginOrSignupStep, StepProp>;

const stepProps: StepProps = {
	email: {
		canNavigateForward: true,
	},
	forgot_password: {},
	login: {
		canNavigateForward: true,
	},
	signup: {
		canNavigateForward: true,
	},
	name: {
		canNavigateForward: true,
	},
	marketing_consent: {
		canNavigateForward: true,
	},
	email_verification: {},
	no_password: {},
};

export function canNavigateForward(step: LoginOrSignupStep): boolean {
	return !!stepProps[step].canNavigateForward;
}
