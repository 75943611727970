import type { LoginOrSignupState, LoginOrSignupStepFormState } from 'src/app/pages/loginOrSignup/shared/types';

import { validateCurrentStepFormFields } from './mutations';

export const initialStepFormState: LoginOrSignupStepFormState = {
	email: '',
	firstName: '',
	lastName: '',
	password: '',
	marketingConsent: undefined,
};

// this allows us to set the validation status for the first render,
// so the submit button is grayed out in the html sent by SSR
export const initialState: LoginOrSignupState = validateCurrentStepFormFields({
	...initialStepFormState,
	currentStep: 'email',
	previousStep: null,
	banner: null,
	stepState: null,
	formErrors: null,
	retryMinimumTimestamp: 0,
	newGDPRUserCreatedViaSocialAuth: false,
});
