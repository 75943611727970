import { FCM_SIGNATURE_GROWTH_GOOGLE_ONE_TAP_LOGIN_CONFIG } from '@change-corgi/fcm/configs';

import { createExperimentFcmConfig } from 'src/app/shared/hooks/experiment';

export const FCM_EXPERIMENT_SIGNATURE_GROWTH_GOOGLE_ONE_TAP_LOGIN = createExperimentFcmConfig({
	experimentName: ({ fcm: { config } }) => config.experiment_name,
	fcmConfigs: {
		config: FCM_SIGNATURE_GROWTH_GOOGLE_ONE_TAP_LOGIN_CONFIG,
	},
	isEnabled: ({ fcm: { config } }) => {
		return config.enabled;
	},
});
