import { useLoginSocialButtons } from 'src/app/pages/loginOrSignup/shared/hooks';

type Result = ModelHookResult<{
	showSocialButtons: boolean;
}>;

export function useEmailStep(): Result {
	const {
		data: { buttons },
	} = useLoginSocialButtons();

	return {
		data: {
			showSocialButtons: !!buttons.length,
		},
	};
}
