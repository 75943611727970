import { useCallback } from 'react';

import { useNavigate } from '@change-corgi/core/react/router';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import type { LoginOrJoinMethod, LoginOrJoinMethodShortcode } from 'src/app/shared/utils/loginOrJoin';
import { LOGIN_OR_JOIN_METHOD_QUERY_KEY, LOGIN_OR_JOIN_METHODS } from 'src/app/shared/utils/loginOrJoin';

import { useGetRedirectUrl } from '../useGetRedirectUrl';

type GotoRedirectToParams = {
	loginOrJoinMethod?: LoginOrJoinMethod;
};

type GotoRedirectFn = (params?: GotoRedirectToParams) => void;

const getLoginOrJoinMethodShortcode = (loginOrJoinMethod?: string): string | undefined =>
	Object.keys(LOGIN_OR_JOIN_METHODS).find(
		(key) => LOGIN_OR_JOIN_METHODS[key as LoginOrJoinMethodShortcode] === loginOrJoinMethod,
	);

export function useGotoRedirect(): GotoRedirectFn {
	const navigate = useNavigate();
	const { resolveUrl } = useUtilityContext();
	const getRedirectUrl = useGetRedirectUrl();

	const gotoRedirectTo = useCallback(
		({ loginOrJoinMethod }: GotoRedirectToParams = {}) => {
			const methodShortcode = getLoginOrJoinMethodShortcode(loginOrJoinMethod);
			const url = getRedirectUrl({
				additionalQueryParams: {
					// adds the loginOrJoin method to the url we're being redirected to as a query param
					...(methodShortcode ? { [LOGIN_OR_JOIN_METHOD_QUERY_KEY]: methodShortcode } : {}),
				},
			});
			// using resolveUrl() here results in not using the react-router for navigation
			// (which is what we want since the user has just changed and we want to refresh l10n)
			navigate(resolveUrl(url));
		},
		[navigate, resolveUrl, getRedirectUrl],
	);

	return gotoRedirectTo;
}
