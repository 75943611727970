import { useLoginOrSignupPageState } from 'src/app/pages/loginOrSignup/pageContext';
import type { LoginOrSignupStep } from 'src/app/pages/loginOrSignup/shared/types';

type Result = ModelHookResult<{
	step: LoginOrSignupStep;
}>;

export function useLoginOrSignupBody(): Result {
	const [{ currentStep }] = useLoginOrSignupPageState();

	return {
		data: {
			step: currentStep,
		},
	};
}
