import type { JSX, PropsWithChildren } from 'react';

import type { TranslationKey } from '@change-corgi/core/i18n';
import { Translate, useI18n } from '@change-corgi/core/react/i18n';
import { Button } from '@change-corgi/design-system/components/actions';
import { Box, Flex } from '@change-corgi/design-system/layout';

type Props = {
	forwardText: TranslationKey | null;
	desktopBackwardText: TranslationKey | null;
	mobileBackwardText: TranslationKey | null;
	forwardFunc: () => void | Promise<void>;
	backwardFunc: () => void | Promise<void>;
	validated: boolean;
	nextStepLoading: boolean;
};

type ActionButtonProps = {
	forwardText: TranslationKey | null;
	backwardText: TranslationKey | null;
	forwardFunc: () => void | Promise<void>;
	backwardFunc: () => void | Promise<void>;
	validated: boolean;
	nextStepLoading: boolean;
};

export function NavButtons({
	forwardText,
	desktopBackwardText,
	mobileBackwardText,
	forwardFunc,
	backwardFunc,
	validated,
	nextStepLoading,
}: Props): JSX.Element {
	return (
		<>
			<MobileStyling>
				<ActionButtons
					forwardText={forwardText}
					backwardText={mobileBackwardText}
					forwardFunc={forwardFunc}
					backwardFunc={backwardFunc}
					validated={validated}
					nextStepLoading={nextStepLoading}
				/>
			</MobileStyling>
			<DesktopStyling>
				<ActionButtons
					forwardText={forwardText}
					backwardText={desktopBackwardText}
					forwardFunc={forwardFunc}
					backwardFunc={backwardFunc}
					validated={validated}
					nextStepLoading={nextStepLoading}
				/>
			</DesktopStyling>
		</>
	);
}

function ActionButtons({
	forwardText,
	backwardText,
	forwardFunc,
	backwardFunc,
	validated,
	nextStepLoading,
}: ActionButtonProps) {
	const { translate } = useI18n();

	if (!forwardText && !backwardText) return null;

	const translatedForwardText = forwardText && translate(forwardText);
	return (
		<>
			{backwardText ? (
				<Button
					mr={16}
					variant="secondary"
					size="large"
					data-testid="back"
					onClick={backwardFunc}
					disabled={nextStepLoading}
				>
					<Translate value={backwardText} />
				</Button>
			) : (
				<div></div>
			)}
			{translatedForwardText ? (
				<Button
					size="large"
					loading={nextStepLoading}
					loadingLabel="loading" // TODO translate label
					disabled={!validated && !nextStepLoading}
					data-testid="save-and-continue-click"
					onClick={forwardFunc}
				>
					{translatedForwardText}
				</Button>
			) : (
				<div></div>
			)}
		</>
	);
}

function DesktopStyling({ children }: PropsWithChildren<EmptyProps>) {
	return (
		<Box sx={{ display: ['none', 'block'] }} pb={64}>
			<Flex sx={{ justifyContent: 'flex-end' }} mt={24}>
				{children}
			</Flex>
		</Box>
	);
}

const HEIGHT = 90;

function MobileStyling({ children }: PropsWithChildren<EmptyProps>) {
	return (
		<Box
			sx={{
				display: ['block', 'none'],
				// using a height here to reserve the space in the main container
				// so that scrolling can make the content under the nav bar appear
				height: HEIGHT,
			}}
		>
			<Box sx={{ position: 'fixed', bottom: 0, left: 0, width: '100%', zIndex: 2000 }}>
				<Flex
					sx={{
						height: HEIGHT,
						alignItems: 'center',
						borderTopWidth: 1,
						borderTopStyle: 'solid',
						borderTopColor: 'neutral-grey100',
						backgroundColor: 'white',
					}}
					px={16}
				>
					<Flex sx={{ width: '100%', alignItems: 'stretch', justifyContent: 'space-between' }}>{children}</Flex>
				</Flex>
			</Box>
		</Box>
	);
}
