import { useMemo } from 'react';

import { useNow } from '@change-corgi/core/react/date';

import { useLoginOrSignupPageState } from 'src/app/pages/loginOrSignup/pageContext';
import { isLoading } from 'src/app/shared/utils/async';

export function useCanSubmitForm(): boolean {
	const [{ currentStep, stepState, formErrors, retryMinimumTimestamp }] = useLoginOrSignupPageState();
	const now = useNow(5000); // every 5 sec
	const isNowAfterRetryMin = useMemo(() => now.getTime() > retryMinimumTimestamp, [now, retryMinimumTimestamp]);

	const loading = !!stepState && isLoading(stepState);

	if (currentStep !== 'login') {
		return !formErrors && !loading;
	}

	return !formErrors && !loading && isNowAfterRetryMin;
}
