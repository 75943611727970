import isStrongPassword from 'validator/lib/isStrongPassword';

type IsPasswordOptions = {
	type: 'weak' | 'strong';
};

const passwordOptions = {
	weak: { minLength: 1, minLowercase: 0, minUppercase: 0, minNumbers: 0, minSymbols: 0, returnScore: false as const },
	strong: {
		minLength: 10,
		minLowercase: 0,
		minUppercase: 0,
		minNumbers: 0,
		minSymbols: 0,
		returnScore: false as const,
	},
};

export const isPassword = (password: string, options: IsPasswordOptions = { type: 'strong' }): boolean =>
	options.type === 'weak'
		? isStrongPassword(password.trim(), passwordOptions.weak)
		: isStrongPassword(password.trim(), passwordOptions.strong);
