import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import {
	SendResetPasswordLink,
	type SendResetPasswordLinkMutation,
	type SendResetPasswordLinkMutationVariables,
} from './sendResetPasswordLink.graphql';

export type SendResetPasswordLinkInput = SendResetPasswordLinkMutationVariables;

export async function sendResetPasswordLink(
	input: SendResetPasswordLinkMutationVariables,
	{ gql: { fetch } }: UtilityContext,
): Promise<boolean> {
	const { data, errors } = await fetch<SendResetPasswordLinkMutation, SendResetPasswordLinkMutationVariables>({
		query: SendResetPasswordLink,
		variables: input,
		rejectOnError: false,
	});

	if (errors) throw new Error(JSON.stringify(errors));

	return !!data?.sendResetPasswordLink?.success;
}
