import { useCallback } from 'react';

import { useLoginOrSignupPageState } from 'src/app/pages/loginOrSignup/pageContext';

export type LoginOrSignupUserHook = {
	setNewGDPRUserCreatedViaSocialAuth: (newGDPRUserCreatedViaSocialAuth: boolean) => void;
	setUseDifferentEmail: () => void;
};

export function useLoginOrSignupUser(): LoginOrSignupUserHook {
	const [, { setNewGDPRUserCreatedViaSocialAuth, gotoStep }] = useLoginOrSignupPageState();

	return {
		setNewGDPRUserCreatedViaSocialAuth,
		setUseDifferentEmail: useCallback((): void => {
			gotoStep('email');
		}, [gotoStep]),
	};
}
