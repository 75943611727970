import { useCallback, useState } from 'react';

import { getWindow } from '@change-corgi/core/window';

import type { SocialProfile } from '../shared/types';

type Result =
	| { status: 'not_started' | 'loading' | 'error' }
	| {
			status: 'success';
			profile: SocialProfile;
	  };

type FbApiResponse = {
	email: string;
	first_name: string;
	last_name: string;
	name: string;
	picture?: { data?: { url: string } | null };
};

export function useFacebookProfile(): {
	loadFacebookProfile: () => void;
	result: Result;
} {
	const [result, setResult] = useState<Result>({ status: 'not_started' });

	const loadFacebookProfile = useCallback(() => {
		setResult({ status: 'loading' });
		// TODO: Add this capability to fe-core's facebook utility
		try {
			getWindow().FB.api(
				'/me',
				{ fields: ['email', 'name', 'first_name', 'last_name', 'picture'] },
				({ email, name: displayName, first_name: firstName, last_name: lastName, picture }: FbApiResponse) => {
					setResult({
						status: 'success',
						profile: {
							email,
							displayName,
							firstName,
							lastName,
							photoUrl: picture?.data?.url || undefined,
						},
					});
				},
			);
		} catch (e) {
			setResult({ status: 'error' });
		}
	}, []);

	return { loadFacebookProfile, result };
}
