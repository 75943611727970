import { useCallback } from 'react';

import { useLoginOrSignupPageState } from 'src/app/pages/loginOrSignup/pageContext';
import {
	useCanSubmitForm,
	useLoginOrSignupState,
	useLoginOrSignupTracking,
} from 'src/app/pages/loginOrSignup/shared/hooks';

type StepData = { password: string };
type StepDataErrors = Record<`${keyof StepData}Error`, string | null>;

type Result = ModelHookResult<
	StepData & StepDataErrors,
	{
		setStepData: (data: Partial<StepData>) => void;
		submitStep: () => Promise<void>;
		trackStepView: () => void;
	}
>;

export function useSignupStep(): Result {
	const { gotoNextStep } = useLoginOrSignupState();
	const [{ password, formErrors }, { setFormData }] = useLoginOrSignupPageState();
	const { trackLoginPasswordCreateView } = useLoginOrSignupTracking();

	const canSubmit = useCanSubmitForm();

	const submitStep = useCallback(async () => {
		if (!canSubmit) return;
		await gotoNextStep();
	}, [gotoNextStep, canSubmit]);

	return {
		data: {
			password,
			passwordError: formErrors?.password ?? null,
		},
		actions: {
			setStepData: setFormData,
			submitStep,
			trackStepView: trackLoginPasswordCreateView,
		},
	};
}
