import type { PrefetchContext } from 'src/shared/prefetch';

import type { MergedState } from 'src/app/shared/hooks/state';
import { useMergedStates } from 'src/app/shared/hooks/state';

import type { LoginOrSignupPageFcmState } from './pageFcm';
import { prefetchLoginOrSignupPageFcm, useAsyncLoginOrSignupPageFcm } from './pageFcm';
import { useAsyncLoginOrSignupPageFcmExperiments } from './pageFcmExperiments';

export type LoginOrSignupPageState = LoginOrSignupPageFcmState;

export type CustomLoginOrSignupCombinedState = MergedState<
	ReturnType<typeof useAsyncLoginOrSignupPageFcm>,
	ReturnType<typeof useAsyncLoginOrSignupPageFcmExperiments>
>;

export function usePageContext(): CustomLoginOrSignupCombinedState {
	return useMergedStates(useAsyncLoginOrSignupPageFcm(), useAsyncLoginOrSignupPageFcmExperiments());
}

export async function prefetchPageContext(context: PrefetchContext): Promise<void> {
	await prefetchLoginOrSignupPageFcm(context);
}
