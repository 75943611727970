import { useLoginOrSignupPageState } from 'src/app/pages/loginOrSignup/pageContext';
import { isError } from 'src/app/shared/utils/async';

type Result = ModelHookResult<{
	messageHtml: string | undefined;
}>;

export function useLoginOrSignupBanner(): Result {
	const [{ stepState, banner }] = useLoginOrSignupPageState();

	const errorMessage = stepState && isError(stepState) ? stepState.error : undefined;

	return {
		data: {
			messageHtml: errorMessage || banner || undefined,
		},
	};
}
