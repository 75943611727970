import { useEffect } from 'react';

import { useLoginOrSignupTracking } from 'src/app/pages/loginOrSignup/shared/hooks';

export function useTrackLoginOrSingupPageView(): void {
	const { trackLoginOrJoinView } = useLoginOrSignupTracking();

	useEffect(() => {
		trackLoginOrJoinView();
		// eslint-disable is required since this tracking function is in a useMemo with email as a dependency
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
}
