import { useCallback } from 'react';

import qs from 'qs';
import { useLocation } from 'react-router';

import { useNavigate } from '@change-corgi/core/react/router';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { useLoginOrSignupPageState } from 'src/app/pages/loginOrSignup/pageContext';
import { useCanSubmitForm, useLoginOrSignupState } from 'src/app/pages/loginOrSignup/shared/hooks';
import { logout } from 'src/app/shared/api/auth';
import { useLoginState } from 'src/app/shared/hooks/session';

type StepData = { email: string };
type StepDataErrors = Record<`${keyof StepData}Error`, string | null>;

type Result = ModelHookResult<
	StepData &
		StepDataErrors & {
			identified: boolean;
		},
	{
		setStepData: (data: Partial<StepData>) => void;
		submitStep: () => Promise<void>;
		changeUser: () => Promise<void>;
	}
>;

export function useLoginEmailForm(): Result {
	const loginState = useLoginState();
	const utilityContext = useUtilityContext();
	const { gotoNextStep } = useLoginOrSignupState();
	const navigate = useNavigate();
	const location = useLocation();
	const [{ email, formErrors }, { setFormData }] = useLoginOrSignupPageState();

	const canSubmit = useCanSubmitForm();

	const submitStep = useCallback(async () => {
		if (!canSubmit) return;
		await gotoNextStep();
	}, [gotoNextStep, canSubmit]);

	const changeUser = useCallback(async () => {
		await logout(utilityContext);
		navigate(
			`${location.pathname}${qs.stringify(
				{
					...qs.parse(location.search, { ignoreQueryPrefix: true }),
					// using this so that screen readers understand the page has changed
					change_user: true,
				},
				{ addQueryPrefix: true },
			)}`,
			{ forceMode: 'external', replace: true },
		);
	}, [utilityContext, location, navigate]);

	return {
		data: {
			email,
			emailError: formErrors?.email ?? null,
			identified: loginState === 'IDENTIFIED',
		},
		actions: {
			setStepData: setFormData,
			submitStep,
			changeUser,
		},
	};
}
