import { useCallback } from 'react';

import { useLoginOrSignupPageState } from 'src/app/pages/loginOrSignup/pageContext';
import {
	useCanSubmitForm,
	useLoginOrSignupState,
	useLoginOrSignupTracking,
} from 'src/app/pages/loginOrSignup/shared/hooks';

import { useSendResetPasswordLink } from './useSendResetPasswordLink';

type StepData = { password: string };
type StepDataErrors = Record<`${keyof StepData}Error`, string | null>;

type Result = ModelHookResult<
	StepData & StepDataErrors,
	{
		setStepData: (data: Partial<StepData>) => void;
		submitStep: () => Promise<void>;
		trackStepView: () => void;
		sendResetPasswordLink: () => void;
	}
>;

export function useLoginStep(): Result {
	const { gotoNextStep } = useLoginOrSignupState();
	const [{ password, formErrors }, { setFormData }] = useLoginOrSignupPageState();
	const { trackLoginPasswordSubmitView, trackResetPassword } = useLoginOrSignupTracking();
	const sendResetPasswordLink = useSendResetPasswordLink();

	const canSubmit = useCanSubmitForm();

	const submitStep = useCallback(async () => {
		if (!canSubmit) return;
		await gotoNextStep();
	}, [gotoNextStep, canSubmit]);

	return {
		data: {
			password,
			passwordError: formErrors?.password ?? null,
		},
		actions: {
			setStepData: setFormData,
			submitStep,
			trackStepView: trackLoginPasswordSubmitView,
			sendResetPasswordLink: useCallback(() => {
				void sendResetPasswordLink();
				trackResetPassword();
			}, [trackResetPassword, sendResetPasswordLink]),
		},
	};
}
