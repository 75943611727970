import { createStateContext } from '@change-corgi/core/react/context';

import type { LoginOrSignupState } from 'src/app/pages/loginOrSignup/shared/types';

import { initialState } from './initialState';
import { mutations, validateCurrentStepFormFields } from './mutations';

export const {
	StateContext: LoginOrSignupPageStateContext,
	StateProvider: LoginOrSignupPageStateContextProvider,
	useStateContext: useLoginOrSignupPageState,
} = createStateContext<LoginOrSignupState, typeof mutations, { email: string | undefined }>({
	name: 'LoginOrSignupPageStateContext',
	initialState: ({ email }) => {
		return validateCurrentStepFormFields({
			...initialState,
			email: email || '',
		});
	},
	mutations,
});
