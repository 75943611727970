/* eslint-disable max-lines-per-function */
import { useCallback, useMemo } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { useLoginOrSignupPageFcm, useLoginOrSignupPageState } from 'src/app/pages/loginOrSignup/pageContext';
import { useQueryParamValue } from 'src/app/shared/hooks/location';
import type { MarketingCommsConsent } from 'src/app/shared/hooks/session';
import { useSetMarketingCommsConsent } from 'src/app/shared/hooks/session';

import { useLoginOrSignupTracking } from '../../../track';
import { useLoginUserFlow } from '../../../useLoginUserFlow';
import { useTrackSuccess } from '../useTrackSuccess';

import { useInitializeFinishSignup } from './useInitializeFinishSignup';

type GotoFinishSignupHook = () => Promise<void>;

export function useGotoFinishSignup(): GotoFinishSignupHook {
	const [
		{ email, firstName, lastName, password, marketingConsent, newGDPRUserCreatedViaSocialAuth },
		{ setSuccess, gotoStep, setLoading, setError },
	] = useLoginOrSignupPageState();
	const utilityContext = useUtilityContext();
	const { countryCode, gotoRedirectTo, signup } = useInitializeFinishSignup();
	const { translate } = useI18n();
	const trackSuccess = useTrackSuccess();
	const redirectTo = useQueryParamValue('redirectTo');
	const saveConsent = useSetMarketingCommsConsent();
	const { trackLoginNewUserCreateError, trackLoginOrJoinAttempt } = useLoginOrSignupTracking();
	const userFlow = useLoginUserFlow();

	const memoizedUserParams = useMemo(
		() => ({ email, firstName, lastName, password, countryCode }),
		[email, firstName, lastName, password, countryCode],
	);

	const { doubleOptinEnabled: isDoubleOptin } = useLoginOrSignupPageFcm();

	const createRegularUser = useCallback(async () => {
		trackLoginOrJoinAttempt('sign_up', 'email_signup_no_verify', true, userFlow);
		const result = await signup(memoizedUserParams, utilityContext);
		setSuccess();
		let userId;
		if (typeof result !== 'boolean') userId = result.id;
		await trackSuccess({
			userId,
			newUser: true,
			loginMethod: 'email',
			loginOrJoinMethod: 'email_signup_no_verify',
			loginType: 'email_signup',
			userFlow,
		});
		gotoRedirectTo({ loginOrJoinMethod: 'email_signup_no_verify' });
	}, [
		setSuccess,
		gotoRedirectTo,
		memoizedUserParams,
		signup,
		trackSuccess,
		utilityContext,
		trackLoginOrJoinAttempt,
		userFlow,
	]);

	// pending user expects a consentResponse and an optional redirectTo params
	const createPendingUser = useCallback(async () => {
		trackLoginOrJoinAttempt('sign_up', 'email_verify', true, userFlow);
		await signup(
			{
				...memoizedUserParams,
				consentResponse: marketingConsent,
				redirectTo,
			},
			utilityContext,
		);
		setSuccess();
		gotoStep('email_verification');
	}, [
		setSuccess,
		gotoStep,
		marketingConsent,
		memoizedUserParams,
		signup,
		utilityContext,
		trackLoginOrJoinAttempt,
		redirectTo,
		userFlow,
	]);

	const updateGDPRUserConsentResponse = useCallback(async () => {
		/**
		 * we have 2 types of consent the user_service supports
		 * when creating a pending user and then use it to verify - PendingUserConsentInput - https://github.com/change/user_service/blob/master/app/user_service/service_objects/users/pending/verify.rb#L37
		 * when creating a consent response - ConsentInput - https://github.com/change/user_service/blob/master/app/user_service/service_objects/users/consent_responses/create.rb#L17
		 * in our login/signup flow we use PendingUserConsentInput to support pending user creation,
		 * but to update social auth users, we need to use casting to support ConsentInput
		 */
		const consentInput = {
			consentGiven: marketingConsent?.marketingCommsConsent,
			// gives - Unsafe assignment of an `any` value - without the as JSON
			text: marketingConsent?.text as JSON,
			name: marketingConsent?.name,
		};
		// set marketing consent
		await saveConsent(consentInput as MarketingCommsConsent);
		setSuccess();
		gotoRedirectTo();
	}, [
		setSuccess,
		gotoRedirectTo,
		marketingConsent?.marketingCommsConsent,
		marketingConsent?.name,
		marketingConsent?.text,
		saveConsent,
	]);

	const gotoFinishSignup = useCallback(async () => {
		setLoading();
		try {
			/**
			 * If a user signed up through social auth in a GDPR country,
			 * we only need to update the existing user's consent response.
			 */
			if (newGDPRUserCreatedViaSocialAuth) {
				await updateGDPRUserConsentResponse();
			} else if (isDoubleOptin) {
				/**
				 * If isDoubleOptin is true, a pending user is created and
				 * the user will need to verify their email address.
				 */
				await createPendingUser();
			} else {
				/**
				 * The user does not need to provide their marketing consent nor
				 * does the user need to verify their email address.
				 */
				await createRegularUser();
			}
		} catch (error: unknown) {
			let err;
			if (error instanceof Error && error.message === 'PasswordBlocklistedError') {
				err = translate('corgi.login_or_signup.error.login.invalid_password');
			} else if (typeof error === 'string') {
				err = error;
			} else {
				err = translate('corgi.login_or_signup.error.network');
			}
			trackLoginNewUserCreateError();
			setError(err);
		}
	}, [
		createPendingUser,
		createRegularUser,
		setLoading,
		setError,
		isDoubleOptin,
		newGDPRUserCreatedViaSocialAuth,
		trackLoginNewUserCreateError,
		updateGDPRUserConsentResponse,
		translate,
	]);

	return gotoFinishSignup;
}
