import { useCallback } from 'react';

import { useLoginOrSignupPageState } from 'src/app/pages/loginOrSignup/pageContext';
import type { LoginOrSignupState } from 'src/app/pages/loginOrSignup/shared/types';

import { useLoginOrSignupNavigation } from './useLoginOrSignupNavigation';
import { useLoginOrSignupUser } from './useLoginOrSignupUser';

type LoginOrSignupStateHook = {
	readonly state: LoginOrSignupState;
} & ReturnType<typeof useLoginOrSignupNavigation> &
	ReturnType<typeof useLoginOrSignupUser>;

export function useLoginOrSignupState(): LoginOrSignupStateHook {
	const [state] = useLoginOrSignupPageState();
	const { gotoNextStep, gotoStep } = useLoginOrSignupNavigation();
	const { setNewGDPRUserCreatedViaSocialAuth, setUseDifferentEmail } = useLoginOrSignupUser();

	const setDifferentEmail = useCallback(() => {
		setUseDifferentEmail();
	}, [setUseDifferentEmail]);

	return {
		state,
		gotoNextStep,
		gotoStep,
		setNewGDPRUserCreatedViaSocialAuth,
		setUseDifferentEmail: setDifferentEmail,
	};
}
