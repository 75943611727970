import { useEffect } from 'react';
import type { JSX } from 'react';

import isEmail from 'validator/lib/isEmail';

import { Translate } from '@change-corgi/core/react/i18n';
import { ButtonLink } from '@change-corgi/design-system/components/actions';
import { Icon } from '@change-corgi/design-system/components/icon';
import { iconGoogle, iconOpenInNew } from '@change-corgi/design-system/icons';
import { Box } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import { useForgotPasswordStep } from './hooks/useForgotPasswordStep';

export function ForgotPasswordStep(): JSX.Element {
	const {
		data: { email },
		actions: { trackOpenInGmailClick, trackStepView },
	} = useForgotPasswordStep();

	useEffect(() => {
		trackStepView(!isEmail(email, { host_blacklist: ['gmail.com'] }));
	}, [trackStepView, email]);
	return (
		<>
			{!isEmail(email, { host_blacklist: ['gmail.com'] }) && (
				<Box px={[16, 0]} py={16}>
					<ButtonLink
						iconPosition="start"
						mode="icon+label"
						onClick={trackOpenInGmailClick}
						size="medium"
						sx={{
							width: '100%',
						}}
						variant="secondary"
						target="_blank"
						to="https://mail.google.com/mail/u/0/#search/from%3A(%40a.change.org)+in%3Aanywhere"
					>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<Icon color="social-gmail" icon={iconGoogle} size="xl" />
							<Text as="span" size="large" mx={10}>
								<Translate value="corgi.login_or_signup.forgot_password.open_gmail_button" />
							</Text>
							<Icon color="primary-black" icon={iconOpenInNew} size="xl" />
						</Box>
					</ButtonLink>
				</Box>
			)}
		</>
	);
}
