import type { JSX } from 'react';

import { Box } from '@change-corgi/design-system/layout';
import { Heading, Text } from '@change-corgi/design-system/typography';

import { useLoginOrSignupHeader } from './hooks/useLoginOrSignupHeader';

export function LoginOrSignupHeader(): JSX.Element {
	const {
		data: { step, title, subtitle },
	} = useLoginOrSignupHeader();

	return (
		<Box px={[16, 0]} pt={8}>
			<Heading mt={[40, 64]} mb={16} size="h1" as="h1" data-qa={`${step}-step-title`}>
				{title}
			</Heading>
			{subtitle && (
				<Text as="p" size="large" data-qa={`${step}-step-subtitle`}>
					{subtitle}
				</Text>
			)}
		</Box>
	);
}
