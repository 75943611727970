import type { parseHtml } from '@change-corgi/core/react/html';
import { useI18n } from '@change-corgi/core/react/i18n';

import { useLoginOrSignupPageState } from 'src/app/pages/loginOrSignup/pageContext';
import type { LoginOrSignupStep } from 'src/app/pages/loginOrSignup/shared/types';

import { getI18nKeys } from './i18nKeys';

type Result = ModelHookResult<{
	step: LoginOrSignupStep;
	title: string;
	subtitle?: ReturnType<typeof parseHtml>;
}>;

export function useLoginOrSignupHeader(): Result {
	const [{ currentStep, email }] = useLoginOrSignupPageState();
	const { translate, translateHtml } = useI18n();

	const { title, subtitle } = getI18nKeys(currentStep);

	return {
		data: {
			step: currentStep,
			title: translate(title),
			subtitle: subtitle && translateHtml(subtitle, { emailAddress: email }),
		},
	};
}
