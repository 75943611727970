import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type { PendingUserConsentInput, User } from 'src/app/pages/loginOrSignup/shared/types';

import { CreateUser, type CreateUserMutation, type CreateUserMutationVariables } from './createUser.graphql';

export type CreateUserParams = {
	firstName: string;
	lastName: string;
	email: string;
	password: string;
	countryCode: string;
	signupMethod?: CreateUserMutationVariables['input']['signupMethod'];
	consentResponse?: PendingUserConsentInput;
	redirectTo?: string;
};

export async function createUser(
	{
		firstName,
		lastName,
		email,
		password,
		countryCode,
		signupMethod = 'SIGNUP_FORM',
		consentResponse,
		redirectTo,
	}: CreateUserParams,
	{ gql: { fetch } }: UtilityContext,
): Promise<User | boolean> {
	const input = { email, password, firstName, lastName, countryCode, signupMethod, consentResponse, redirectTo };
	const { data, errors } = await fetch<CreateUserMutation, CreateUserMutationVariables>({
		path: '/createUser',
		query: CreateUser,
		variables: { input },
		rejectOnError: false,
		batched: false, // to ensure this rate limited mutation is not batched
	});
	if (errors) throw new Error(JSON.stringify(errors));
	if (!data) throw new Error('No data returned from mutation.');
	if (data.createUser.__typename === 'PasswordBlocklistedError') {
		throw new Error('PasswordBlocklistedError');
	}
	if (data.createUser.__typename === 'UserAlreadyExistsError') throw new Error('UserAlreadyExistsError');
	if (data.createUser.__typename === 'CreatePendingUserSuccess') return data.createUser.success;

	return data.createUser.user;
}
