import { useCallback } from 'react';

import { getHelpUrl } from '@change-corgi/config/help';

import { useLoginOrSignupPageState } from 'src/app/pages/loginOrSignup/pageContext';
import { useLoginOrSignupTracking } from 'src/app/pages/loginOrSignup/shared/hooks';
import { useLocale } from 'src/app/shared/hooks/l10n';

type Result = ModelHookResult<
	{
		supportUrl: string;
	},
	{
		trackStepView: () => void;
		useDifferentEmail: () => void;
	}
>;

export function useEmailVerificationStep(): Result {
	const [, { gotoStep }] = useLoginOrSignupPageState();
	const { trackLoginVerifyEmailView } = useLoginOrSignupTracking();
	const locale = useLocale();

	return {
		data: {
			supportUrl: getHelpUrl(locale, 'contact-us'),
		},
		actions: {
			trackStepView: useCallback(
				() => trackLoginVerifyEmailView('new_user_gdpr', 'sign_up'),
				[trackLoginVerifyEmailView],
			),
			useDifferentEmail: useCallback(() => gotoStep('email'), [gotoStep]),
		},
	};
}
