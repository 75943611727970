/* eslint-disable max-statements */

/* eslint-disable max-lines-per-function */
import { useCallback } from 'react';

import { useTracking } from '@change-corgi/core/react/tracking';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';
import type { TrackingProperties } from '@change-corgi/tracking-properties';

import { useLoginOrSignupPageState } from 'src/app/pages/loginOrSignup/pageContext';
import type { LoginMethod, LoginOrJoinMethod, LoginType } from 'src/app/shared/utils/loginOrJoin';
import { getEmailDomain, getMethod, isFbLoggedIn, NOT_KNOWN } from 'src/app/shared/utils/loginOrJoin';

import { useLoginUserFlow } from '../useLoginUserFlow';

export type SocialConnectSuccess = {
	newUser: boolean;
	userId: string | undefined;
	userFlow: string;
};

export type SuccessfulLoginOrJoinProps = {
	newUser: boolean;
	userId: string | undefined;
	loginMethod: LoginMethod;
	loginOrJoinMethod: LoginOrJoinMethod;
	loginType: LoginType;
	userFlow: string;
};

export type SignupProps = {
	userId: string | undefined;
	loginMethod: LoginMethod;
	loginOrJoinMethod: LoginOrJoinMethod;
	loginType: LoginType;
	userFlow: string;
};

export type GetSuccessPropsType = {
	commonProps: TrackingProperties;
	email: string;
	loginMethod: LoginMethod;
	loginOrJoinMethod: LoginOrJoinMethod;
	loginType: LoginType;
	newUser: boolean;
};

export function initializeLoginSignupProps(facebook: {
	isConnected: boolean;
	authStatus: string | undefined;
}): TrackingProperties {
	return {
		connected_to_facebook: facebook.isConnected,
		connected_to_google: NOT_KNOWN,
		context: 'combined',
		email_domain: NOT_KNOWN,
		fb_logged_in: isFbLoggedIn(facebook.authStatus),
		google_logged_in: NOT_KNOWN,
		google_permissions: 'none',
		has_set_password: false,
		new_user: NOT_KNOWN,
	};
}

export function getLoginProps(commonProps: TrackingProperties, email: string): TrackingProperties {
	return {
		...commonProps,
		new_user: false,
		has_set_password: true,
		context: 'log_in',
		email_domain: getEmailDomain(email),
	};
}

export function getSignupProps(commonProps: TrackingProperties, email: string): TrackingProperties {
	return {
		...commonProps,
		new_user: true,
		context: 'sign_up',
		email_domain: getEmailDomain(email),
	};
}

export function getSuccessProps({
	commonProps,
	email,
	loginMethod,
	loginOrJoinMethod,
	loginType,
	newUser,
}: GetSuccessPropsType): TrackingProperties {
	return {
		...commonProps,
		email_domain: email ? getEmailDomain(email) : NOT_KNOWN,
		new_user: newUser,
		context: newUser ? 'sign_up' : 'log_in',
		connected_to_facebook: loginMethod === 'facebook',
		google_logged_in: loginMethod === 'google',
		connected_to_google: loginMethod === 'google',
		login_or_join_method: loginOrJoinMethod,
		fb_logged_in: loginMethod === 'facebook',
		login_type: loginType,
		has_set_password: loginMethod === 'email',
	};
}

type TrackingEvents = {
	trackLoginButtonClick: () => void;
	trackLoginError: (loginOrJoinMethod: string, errorMessage: string) => void;
	trackLoginNewUserCreateError: () => void;
	trackLoginNewUserNameClickContinue: () => void;
	trackLoginNewUserNameView: () => void;
	trackLoginOrJoinAttempt: (
		loginOrSignupContext: string,
		loginOrJoinMethod: string,
		hasSetPassword: boolean,
		userFlow: string,
	) => void;
	trackLoginOrJoinView: () => void;
	trackLoginPasswordCreateClick: () => void;
	trackLoginPasswordCreateView: () => void;
	trackForgotPasswordCreateView: (isGmail: boolean) => void;
	trackForgotPasswordGmailCreateClick: () => void;
	trackLoginPasswordSubmitView: () => void;
	trackLoginResendMagicLinkClick: () => void;
	trackLoginVerifyEmailView: (reasonForVerifying: string, loginOrJoinContext: string) => void;
	trackResetPassword: () => void;
	trackSignup: ({ userId, loginMethod, loginOrJoinMethod, loginType, userFlow }: SignupProps) => void;
	trackSuccessfulLoginOrJoin: ({
		newUser,
		userId,
		loginMethod,
		loginOrJoinMethod,
		loginType,
		userFlow,
	}: SuccessfulLoginOrJoinProps) => void;
	trackNoPasswordView: (context: { isGmail: boolean }) => void;
	trackNoPasswordGmailClick: () => void;
};

export function useLoginOrSignupTracking(): TrackingEvents {
	const [{ email }] = useLoginOrSignupPageState();
	const { facebook } = useUtilityContext();
	const track = useTracking();
	const userFlow = useLoginUserFlow();
	const getCommonProps = useCallback(() => initializeLoginSignupProps(facebook), [facebook]);

	const trackLoginOrJoinView = useCallback(() => {
		void track('login_or_join_view', { ...getCommonProps(), user_flow: userFlow });
	}, [getCommonProps, track, userFlow]);

	const trackLoginError = useCallback(
		(loginOrJoinMethod: string, errorMessage: string) => {
			void track('login_error', {
				login_or_join_method: loginOrJoinMethod,
				error_message: errorMessage,
				...getCommonProps(),
				has_set_password: true,
				user_flow: userFlow,
			});
		},
		[getCommonProps, track, userFlow],
	);

	const trackSignup = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-shadow
		({ userId, loginMethod, loginOrJoinMethod, loginType, userFlow }: SignupProps) => {
			void track(
				'signup',
				{
					...getSuccessProps({
						commonProps: getCommonProps(),
						email,
						loginMethod,
						loginOrJoinMethod,
						loginType,
						newUser: true,
					}),
					method: getMethod(loginMethod),
					user_flow: userFlow,
				},
				{
					defaultOverrides: { user_id: userId === undefined ? undefined : parseInt(userId, 10) },
				},
			);
		},
		[getCommonProps, email, track],
	);

	const trackSuccessfulLoginOrJoin = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-shadow
		({ newUser, userId, loginMethod, loginOrJoinMethod, loginType, userFlow }: SuccessfulLoginOrJoinProps) => {
			void track(
				'successful_login_or_join',
				{
					...getSuccessProps({
						commonProps: getCommonProps(),
						email,
						loginMethod,
						loginOrJoinMethod,
						loginType,
						newUser,
					}),
					user_flow: userFlow,
				},
				{
					defaultOverrides: { user_id: userId === undefined ? undefined : parseInt(userId, 10) },
				},
			);
		},
		[getCommonProps, email, track],
	);

	const trackResetPassword = useCallback(() => {
		void track('password_reset', { ...getCommonProps(), context: '', has_set_password: true });
	}, [getCommonProps, track]);

	const trackLoginPasswordCreateView = useCallback(() => {
		void track('login_password_create_view', getSignupProps(getCommonProps(), email));
	}, [getCommonProps, email, track]);

	const trackForgotPasswordCreateView = useCallback(
		(isGmail: boolean) => {
			void track('forgot_password_view', { ...getSignupProps(getCommonProps(), email), is_gmail: isGmail });
		},
		[getCommonProps, email, track],
	);

	const trackForgotPasswordGmailCreateClick = useCallback(() => {
		void track('forgot_password_gmail_click', getSignupProps(getCommonProps(), email));
	}, [getCommonProps, email, track]);

	const trackLoginPasswordCreateClick = useCallback(() => {
		void track('login_password_create_click_continue', getSignupProps(getCommonProps(), email));
	}, [getCommonProps, email, track]);

	const trackLoginNewUserNameView = useCallback(() => {
		void track('login_new_user_name_view', getSignupProps(getCommonProps(), email));
	}, [getCommonProps, email, track]);

	const trackLoginNewUserNameClickContinue = useCallback(() => {
		void track('login_new_user_name_click_continue', getSignupProps(getCommonProps(), email));
	}, [getCommonProps, email, track]);

	const trackLoginNewUserCreateError = useCallback(() => {
		void track('login_new_user_create_error', getSignupProps(getCommonProps(), email));
	}, [getCommonProps, email, track]);

	const trackLoginPasswordSubmitView = useCallback(() => {
		void track('login_password_submit_view', getLoginProps(getCommonProps(), email));
	}, [getCommonProps, email, track]);

	const trackLoginButtonClick = useCallback(() => {
		void track('email_login_button_click', getLoginProps(getCommonProps(), email));
	}, [getCommonProps, email, track]);

	const trackLoginResendMagicLinkClick = useCallback(() => {
		void track('login_resend_magic_link_click', getSignupProps(getCommonProps(), email));
	}, [getCommonProps, email, track]);

	const trackLoginVerifyEmailView = useCallback(
		(reasonForVerifying: string, loginOrJoinContext: string) => {
			void track('login_verify_email_view', {
				...getCommonProps(),
				reason_for_verifying: reasonForVerifying,
				context: loginOrJoinContext,
				new_user: true,
				email_domain: getEmailDomain(email),
			});
		},
		[getCommonProps, email, track],
	);

	const trackLoginOrJoinAttempt = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-shadow
		(loginOrSignupContext: string, loginOrJoinMethod: string, hasSetPassword: boolean, userFlow: string) => {
			void track('login_or_join_attempt', {
				...getCommonProps(),
				login_or_join_method: loginOrJoinMethod,
				context: loginOrSignupContext,
				email_domain: getEmailDomain(email),
				has_set_password: hasSetPassword,
				user_flow: userFlow,
			});
		},
		[getCommonProps, email, track],
	);

	const trackNoPasswordView = useCallback(
		({ isGmail }: { isGmail: boolean }) => {
			void track('no_password_view', { ...getSignupProps(getCommonProps(), email), is_gmail: isGmail });
		},
		[getCommonProps, email, track],
	);

	const trackNoPasswordGmailClick = useCallback(() => {
		void track('no_password_gmail_click', getSignupProps(getCommonProps(), email));
	}, [getCommonProps, email, track]);

	return {
		trackLoginButtonClick,
		trackLoginError,
		trackLoginNewUserCreateError,
		trackLoginNewUserNameClickContinue,
		trackLoginNewUserNameView,
		trackLoginOrJoinAttempt,
		trackLoginOrJoinView,
		trackLoginPasswordCreateClick,
		trackLoginPasswordCreateView,
		trackForgotPasswordCreateView,
		trackForgotPasswordGmailCreateClick,
		trackLoginPasswordSubmitView,
		trackLoginResendMagicLinkClick,
		trackLoginVerifyEmailView,
		trackResetPassword,
		trackSignup,
		trackSuccessfulLoginOrJoin,
		trackNoPasswordView,
		trackNoPasswordGmailClick,
	};
}
