import type { LoginOrSignupState, LoginOrSignupStepFormState } from 'src/app/pages/loginOrSignup/shared/types';

import { setFormFieldError } from './setFormFieldError';
import { validateCurrentStepFormFields } from './validateCurrentStepFormFields';

export const userMutations = {
	setFormData: (state: LoginOrSignupState, data: Partial<LoginOrSignupStepFormState>): LoginOrSignupState => {
		return validateCurrentStepFormFields({
			...state,
			...data,
		});
	},

	setFormValid: (state: LoginOrSignupState): LoginOrSignupState => ({
		...state,
		formErrors: null,
	}),

	setFormFieldError,

	setRetryMinimumTimestamp: (state: LoginOrSignupState, ts: number): LoginOrSignupState => ({
		...state,
		retryMinimumTimestamp: ts,
	}),

	setBanner: (state: LoginOrSignupState, payload: string): LoginOrSignupState => ({
		...state,
		banner: payload,
	}),

	clearBanner: (state: LoginOrSignupState): LoginOrSignupState => ({
		...state,
		banner: null,
	}),

	setNewGDPRUserCreatedViaSocialAuth: (state: LoginOrSignupState, payload: boolean): LoginOrSignupState => ({
		...state,
		newGDPRUserCreatedViaSocialAuth: payload,
	}),
};
