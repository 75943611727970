import type { JSX } from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { ButtonLink } from '@change-corgi/design-system/components/actions';
import { Icon } from '@change-corgi/design-system/components/icon';
import { iconGoogle, iconOpenInNew } from '@change-corgi/design-system/icons';
import { Box } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import { useNoPasswordStep } from './hooks/useNoPasswordStep';

export function NoPasswordStep(): JSX.Element | null {
	const {
		data: { isGmail },
		actions: { trackOpenInGmailClick },
	} = useNoPasswordStep();

	if (!isGmail) return null;

	return (
		<Box px={[16, 0]} py={16}>
			<ButtonLink
				iconPosition="start"
				mode="icon+label"
				onClick={trackOpenInGmailClick}
				size="medium"
				sx={{
					width: '100%',
				}}
				variant="secondary"
				target="_blank"
				to="https://mail.google.com/mail/u/0/#search/from%3A(%40a.change.org)+in%3Aanywhere"
				data-qa="no-password-step-open-gmail"
				data-testid="no-password-step-open-gmail"
			>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<Icon color="social-gmail" icon={iconGoogle} size="xl" />
					<Text as="span" size="large" mx={10}>
						<Translate value="corgi.login_or_signup.no_password.open_gmail_button" />
					</Text>
					<Icon color="primary-black" icon={iconOpenInNew} size="xl" />
				</Box>
			</ButtonLink>
		</Box>
	);
}
