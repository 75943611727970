import { useMemo } from 'react';

import { useLoginOrSignupPageFcm } from 'src/app/pages/loginOrSignup/pageContext';

type Network = 'facebook' | 'google';

type Result = ModelHookResult<{
	buttons: readonly Network[];
}>;

export function useLoginSocialButtons(): Result {
	const { googleAuthEnabled } = useLoginOrSignupPageFcm();

	return {
		data: {
			buttons: useMemo(() => {
				const buttons: Network[] = [];
				if (googleAuthEnabled) buttons.push('google');
				buttons.push('facebook');
				return buttons;
			}, [googleAuthEnabled]),
		},
	};
}
