import { useCountryCode } from 'src/app/shared/hooks/l10n';

import { useGotoRedirect } from '../../../../useGotoRedirect';
import type { LoginOrSignupHook } from '../../../../useLoginOrSignup';
import { useLoginOrSignup } from '../../../../useLoginOrSignup';

type InitializeFinishSignup = {
	countryCode: string;
	gotoRedirectTo: ReturnType<typeof useGotoRedirect>;
	signup: LoginOrSignupHook['signup'];
};

export function useInitializeFinishSignup(): InitializeFinishSignup {
	const countryCode = useCountryCode();
	const gotoRedirectTo = useGotoRedirect();
	const { signup } = useLoginOrSignup();
	return { countryCode, gotoRedirectTo, signup };
}
