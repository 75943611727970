import type { UtilityContext } from '@change-corgi/core/react/utilityContext';
import type { AuthMethod } from '@change-corgi/middle-end-gql-schema';

import type { User } from 'src/app/pages/loginOrSignup/shared/types';

import type { CreateUserParams } from './api/createUser';
import { createUser } from './api/createUser';
import { getEmailAuthMethod } from './api/getEmailAuthMethod';
import type { LoginByEmailMutationInput, LoginMutationResponse } from './api/loginByEmail';
import { loginByEmail } from './api/loginByEmail';

export type LoginOrSignupHook = {
	getEmailAuthMethod: (
		email: string,
		{
			gql: { fetch },
		}: UtilityContext,
	) => Promise<AuthMethod>;
	login: (
		{ email, password }: LoginByEmailMutationInput,
		{
			gql: { fetch },
		}: UtilityContext,
	) => Promise<LoginMutationResponse>;
	signup: (
		{ email, password, firstName, lastName, countryCode }: CreateUserParams,
		{
			gql: { fetch },
		}: UtilityContext,
	) => Promise<User | boolean>;
};

export function useLoginOrSignup(): LoginOrSignupHook {
	return {
		getEmailAuthMethod,
		login: loginByEmail,
		signup: createUser,
	};
}
