import { useCallback, useEffect } from 'react';

import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { useLoginOrSignupPageFcm, useLoginOrSignupPageFcmExperiments } from 'src/app/pages/loginOrSignup/pageContext';
import type { GoogleLoginResultData } from 'src/app/shared/hooks/auth';
import { useHandleGoogleLogin } from 'src/app/shared/hooks/auth';

import { useGotoRedirect } from '../useGotoRedirect';
import { useLoginOrSignupState } from '../useLoginOrSignupState';
import { useLoginUserFlow } from '../useLoginUserFlow';

const signupContext = 'login_or_signup_social_auth';

export const useGoogleOneTapLogin = ({
	onSuccess,
	onError,
}: {
	onSuccess?: (data: GoogleLoginResultData) => void;
	onError?: (errorReason: 'STAFF_SAML' | 'OTHER') => void;
}) => {
	const { googleAuthEnabled, futureSapMarketingConsentRequired: isFutureSapMarketingConsentRequired } =
		useLoginOrSignupPageFcm();
	const { googleAuth } = useUtilityContext();
	const { googleOneTapLoginExperiment } = useLoginOrSignupPageFcmExperiments();
	const { setNewGDPRUserCreatedViaSocialAuth, gotoStep } = useLoginOrSignupState();
	const userFlow = useLoginUserFlow();
	const gotoRedirectTo = useGotoRedirect();
	const { handleGoogleLogin, result } = useHandleGoogleLogin({
		signupContext,
		loginType: userFlow,
	});
	const isOneTapEnabled = googleAuthEnabled && googleOneTapLoginExperiment.variation === 'variant';

	useEffect(() => {
		if (result.status === 'success') {
			if (!result.data.existingUser && isFutureSapMarketingConsentRequired) {
				setNewGDPRUserCreatedViaSocialAuth(true);
				gotoStep('marketing_consent');
			} else {
				onSuccess ? onSuccess?.(result.data) : gotoRedirectTo({ loginOrJoinMethod: 'manual_google' });
			}
		}
		if (result.status === 'error') {
			onError?.(result.errorReason);
		}
	}, [
		gotoRedirectTo,
		gotoStep,
		isFutureSapMarketingConsentRequired,
		onError,
		onSuccess,
		result,
		setNewGDPRUserCreatedViaSocialAuth,
	]);

	const displayGoogleOneTap = useCallback(() => {
		if (isOneTapEnabled) {
			void googleAuth?.prompt?.(handleGoogleLogin);
		}
	}, [isOneTapEnabled, googleAuth, handleGoogleLogin]);

	return { displayGoogleOneTap };
};
