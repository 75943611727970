import type { JSX } from 'react';

import { parseHtml } from '@change-corgi/core/react/html';
import { PageMessage } from '@change-corgi/design-system/components/alerts';

import { useLoginOrSignupBanner } from './hooks/useLoginOrSignupBanner';

export function LoginOrSignupBanner(): JSX.Element | null {
	const {
		data: { messageHtml },
	} = useLoginOrSignupBanner();

	if (!messageHtml) return null;

	return (
		<PageMessage
			role="alert"
			message={parseHtml(messageHtml)}
			severity="critical"
			data-testid="flash-error"
			data-qa="login-or-signup-error-banner"
		/>
	);
}
