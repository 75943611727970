import { useCallback } from 'react';

import qs from 'qs';

import { isChangeUrl } from '@change-corgi/core/navigation';

import { useQueryParamValue } from 'src/app/shared/hooks/location';

type Result = (options?: { additionalQueryParams?: Record<string, string> }) => string;

export function useGetRedirectUrl(): Result {
	const redirectUrl1 = useQueryParamValue('redirectTo');
	const redirectUrl2 = useQueryParamValue('redirect_to');
	const redirectUrl = redirectUrl1 || redirectUrl2 || '/';
	const safeRedirectUrl = isChangeUrl(redirectUrl) ? redirectUrl : '/';

	return useCallback(
		({ additionalQueryParams } = { additionalQueryParams: undefined }) => {
			const [path, search] = safeRedirectUrl.split('?');

			const query = qs.parse(search);

			// Remove tokens from the query params
			const filteredQueryParams = Object.fromEntries(
				Object.entries(query).filter(([key]) => key !== 'tk' && !key.endsWith('_tk')),
			);

			return `${path}${qs.stringify(
				{
					...filteredQueryParams,
					...(additionalQueryParams || {}),
				},
				{ addQueryPrefix: true },
			)}`;
		},
		[safeRedirectUrl],
	);
}
