import { useEffect } from 'react';
import type { ChangeEvent, JSX } from 'react';

import { Box } from '@change-corgi/design-system/layout';

import { PasswordInput } from '../PasswordInput';

import { useSignupStep } from './hooks/useSignupStep';

export function SignupStep(): JSX.Element {
	const {
		data: { password, passwordError },
		actions: { setStepData, submitStep, trackStepView },
	} = useSignupStep();

	useEffect(() => {
		trackStepView();
	}, [trackStepView]);

	return (
		<Box px={[16, 0]} py={16}>
			<form
				onSubmit={(event) => {
					event.preventDefault();
					void submitStep();
				}}
			>
				<PasswordInput
					showHelp
					onChange={(event: ChangeEvent<HTMLInputElement>) => setStepData({ password: event.target.value })}
					value={password}
					error={passwordError}
				/>
			</form>
		</Box>
	);
}
