import type { UtilityContext } from '@change-corgi/core/react/utilityContext';
import type { AuthMethod } from '@change-corgi/middle-end-gql-schema';

import {
	UserAuthMethod,
	type UserAuthMethodQuery,
	type UserAuthMethodQueryVariables,
} from './getEmailAuthMethod.graphql';

export async function getEmailAuthMethod(email: string, { gql: { fetch } }: UtilityContext): Promise<AuthMethod> {
	const { data, errors } = await fetch<UserAuthMethodQuery, UserAuthMethodQueryVariables>({
		query: UserAuthMethod,
		variables: { email },
		path: '/userAuthMethodByEmail',
		rejectOnError: false,
		batched: false, // to ensure this rate limited mutation is not batched
	});

	if (errors) throw new Error(JSON.stringify(errors));
	if (!data) throw new Error('No data returned from query.');
	return data.authMethod;
}
