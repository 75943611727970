import { useLoginOrSignupPageState } from 'src/app/pages/loginOrSignup/pageContext';
import { useLoginOrSignupTracking } from 'src/app/pages/loginOrSignup/shared/hooks';

type StepData = { email: string };
type Result = ModelHookResult<
	StepData,
	{
		trackOpenInGmailClick: () => void;
		trackStepView: (isGmail: boolean) => void;
	}
>;

export function useForgotPasswordStep(): Result {
	const [{ email }] = useLoginOrSignupPageState();
	const { trackForgotPasswordGmailCreateClick, trackForgotPasswordCreateView } = useLoginOrSignupTracking();
	return {
		data: {
			email,
		},
		actions: {
			trackOpenInGmailClick: trackForgotPasswordGmailCreateClick,
			trackStepView: trackForgotPasswordCreateView,
		},
	};
}
