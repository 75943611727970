import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import { NoPasswordSendEmail } from './api.graphql';
import { type NoPasswordSendEmailMutation, type NoPasswordSendEmailMutationVariables } from './api.graphql';

export async function sendResetPasswordEmail(
	{ gql: { fetch } }: UtilityContext,
	input: NoPasswordSendEmailMutationVariables,
): Promise<boolean> {
	const { data } = await fetch<NoPasswordSendEmailMutation, NoPasswordSendEmailMutationVariables>({
		query: NoPasswordSendEmail,
		variables: input,
		rejectOnError: true,
	});

	if (!data?.sendResetPasswordLink?.success) {
		throw new Error();
	}

	return true;
}
