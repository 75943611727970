import { useCallback } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { useLoginOrSignupPageState } from 'src/app/pages/loginOrSignup/pageContext';

import { sendResetPasswordLink } from './api/sendResetPasswordLink';

export function useSendResetPasswordLink(): () => Promise<void> {
	const [state, { clearError, clearBanner, setError, gotoStep }] = useLoginOrSignupPageState();
	const utilityContext = useUtilityContext();
	const { translate } = useI18n();

	return useCallback(async () => {
		try {
			// Clear error message so if request is successful, the ErrorDisplay
			// component will display banner, instead of stale error message
			clearError();
			clearBanner();
			const success = await sendResetPasswordLink({ email: state.email }, utilityContext);
			if (success) {
				gotoStep('forgot_password', { canGoBack: true });
			} else {
				throw new Error();
			}
		} catch (error: unknown) {
			setError(translate('corgi.login_or_signup.error.network'));
		}
	}, [gotoStep, clearError, clearBanner, setError, utilityContext, state.email, translate]);
}
