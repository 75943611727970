import { useCallback } from 'react';

import { useGetNewSession } from 'src/app/shared/hooks/session';
import { googleTagManagerTracking } from 'src/app/shared/utils/googleTagManagerTracking';
import type { LoginMethod, LoginOrJoinMethod, LoginType } from 'src/app/shared/utils/loginOrJoin';

import { useLoginOrSignupTracking } from '../../../track';

type TrackSuccessProps = {
	loginMethod: LoginMethod;
	loginOrJoinMethod: LoginOrJoinMethod;
	loginType: LoginType;
	newUser: boolean;
	userId?: string | undefined;
	userFlow: string;
};

// FIXME why would a "tracking" hook refresh the session?
function useTrackSuccessBase(): ({ userId }: Partial<TrackSuccessProps>) => Promise<string | undefined> {
	const getNewSession = useGetNewSession();
	const trackSuccessBase = useCallback(
		async ({ userId }: Partial<TrackSuccessProps>) => {
			let currentUserId;
			// Only query a fresh session if we need updated user data
			if (userId) {
				currentUserId = userId;
			} else {
				try {
					const updatedSession = await getNewSession();
					currentUserId = updatedSession.user?.id;
				} catch (e) {
					// do nothing
				}
			}
			return currentUserId;
		},
		[getNewSession],
	);

	return trackSuccessBase;
}

export function useTrackSuccess(): ({
	loginMethod,
	loginOrJoinMethod,
	loginType,
	newUser,
	userId,
	userFlow,
}: TrackSuccessProps) => Promise<void> {
	const trackSuccessBase = useTrackSuccessBase();

	const { trackSignup, trackSuccessfulLoginOrJoin } = useLoginOrSignupTracking();
	const trackSuccess = useCallback(
		async ({ newUser, loginMethod, loginOrJoinMethod, loginType, userId, userFlow }: TrackSuccessProps) => {
			const currentUserId = await trackSuccessBase({ userId });

			if (newUser) {
				trackSignup({
					userId: currentUserId,
					loginMethod,
					loginOrJoinMethod,
					loginType,
					userFlow,
				});
			}

			trackSuccessfulLoginOrJoin({
				newUser,
				userId: currentUserId,
				loginMethod,
				loginOrJoinMethod,
				loginType,
				userFlow,
			});

			if (userFlow === 'future_sap') {
				googleTagManagerTracking.push({ event: 'sap_flow', step: 'login_or_join' });
			}
		},
		[trackSignup, trackSuccessBase, trackSuccessfulLoginOrJoin],
	);

	return trackSuccess;
}
