import {
	FCM_DOUBLE_OPTIN_ENABLED_NEW_USER,
	FCM_FUTURE_SAP_MARKETING_CONSENT_REQUIRED,
	FCM_GOOGLE_AUTH,
} from '@change-corgi/fcm/configs';

import { createFcmContext } from 'src/app/shared/contexts/fcm';
import type { LoadedStateData } from 'src/app/shared/utils/async';

export const {
	FcmContext: LoginOrSignupPageFcmContext,
	FcmProvider: LoginOrSignupPageFcmContextProvider,
	useFcm: useLoginOrSignupPageFcm,
	useAsyncFcm: useAsyncLoginOrSignupPageFcm,
	prefetchFcm: prefetchLoginOrSignupPageFcm,
} = createFcmContext({
	name: 'LoginOrSignupPageFcmContext',
	dataProperty: 'pageFcm',
	configs: {
		doubleOptinEnabled: FCM_DOUBLE_OPTIN_ENABLED_NEW_USER,
		futureSapMarketingConsentRequired: FCM_FUTURE_SAP_MARKETING_CONSENT_REQUIRED,
		googleAuthEnabled: FCM_GOOGLE_AUTH,
	},
	prefetch: true,
});

export type LoginOrSignupPageFcmState = ReturnType<typeof useAsyncLoginOrSignupPageFcm>;
export type LoginOrSignupPageFcm = LoadedStateData<LoginOrSignupPageFcmState>;
