import { useLoginOrSignupPageState } from 'src/app/pages/loginOrSignup/pageContext';
import { useCanSubmitForm, useLoginOrSignupState } from 'src/app/pages/loginOrSignup/shared/hooks';
import { isLoading } from 'src/app/shared/utils/async';

import { canNavigateForward } from './navigationProps';

type Result = ModelHookResult<
	{
		valid: boolean;
		loading: boolean;
		canNavigateBack: boolean;
		canNavigateForward: boolean;
	},
	{
		gotoPreviousStep: () => void;
		gotoNextStep: () => void;
	}
>;

export function useLoginOrSignupFooter(): Result {
	const [{ currentStep, stepState, previousStep }, { gotoPreviousStep }] = useLoginOrSignupPageState();
	// TODO: replace useLoginOrSignupState hook with new hook
	const { gotoNextStep } = useLoginOrSignupState();
	const canSubmit = useCanSubmitForm();

	return {
		data: {
			valid: canSubmit,
			loading: !!stepState && isLoading(stepState),
			canNavigateBack: !!previousStep,
			// FIXME: canNavigateForward info should come from the state or hook
			canNavigateForward: canNavigateForward(currentStep),
		},
		actions: {
			gotoPreviousStep,
			gotoNextStep: () => {
				// FIXME not sure why gotoNextStep needs to be async
				// we definitely don't need it to be async in the Footer
				void gotoNextStep();
			},
		},
	};
}
